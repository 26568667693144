import { CurrentUser } from "stores/CurrentUser";
import type { CVPartnerReactRoute } from "types";
import { renderRouter } from "util/react";
import { NotFound } from "views/errors/404";

export class FallbackRouter implements CVPartnerReactRoute {
  render() {
    return renderRouter(
      [
        {
          path: "*",
          element: (
            <NotFound isLoggedIn={window.cvpartner?.isLoggedIn ?? false} />
          ),
          action: () => {
            if (window.cvpartner?.isLoggedIn) {
              CurrentUser.reload();
            }

            return null;
          },
        },
      ],
      "main",
    );
  }
}
